footer {
    width: 100vw;
    position: relative;
    margin-top: 30px;
}

footer .bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    filter: brightness(50%);
    z-index: -1;
}

#footer {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    max-width: 1200px;
    flex-wrap: wrap;
    margin: auto;
    padding: 80px 20px;
    gap: 50px;
}

#footer .logo {
    width: 200px;
    object-fit: contain;
}

#footer .container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

#footer h3 {
    color: #d8d8d8;
    font-size: 18px;
    font-weight: 500;
}

#footer .contact {
    display: flex;
    gap: 10px;
}

#footer .social {
    display: flex;
    gap: 10px;
}

#footer .icon {
    font-size: 25px;
}

#footer .whatsapp {
    color: #25D366;
}

#footer .email {
    color: white;
}

#footer .fb {
    color: #3b5998;
    border: 1px 3b5998 solid;
    background-color: white;
    border-radius: 50%;
}

#footer .instagram {
    color: white;
    background-color: #8a3ab9;
    border-radius: 9px;
}

#footer .telephone {
    text-decoration: none;
    color: #d8d8d8;
}

#footer .btn {
    font-size: 20px;
    color: white;
    width: 200px;
    background-color: #ff914d;
    margin: 0 10px;
    padding: 12px 30px;
    border-radius: 5px;
    font-weight: 600;
    text-align: center;
    transition: all 0.3s;
    text-decoration: none;
}

#footer .btn:hover {
    cursor: pointer;
    color: #270799;
    background-color: #F59A0E;
}

footer>p {
    text-align: center;
    color: #d8d8d8;
    font-size: 15px;
    padding-bottom: 10px;
}

#footer p {
    color: #d8d8d8;
}

@media screen and (max-width: 700px) {
    #footer .btn, footer .adventure {
        align-self: center;
    }

}
