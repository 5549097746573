#apartments {
    margin: 0 10px;
}

/* POPUP AND SWITCH */

#apartments .popup {
    position: fixed;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 900;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(10px);
    overflow-y: auto;
}

#apartments .none {
    display: none;
}

#apartments .popup>div {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 30px 50px;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.5);
    color: #666666;
}

#apartments .popup>div p {
    width: 250px;
    border-radius: 12px;
    font-size: 18px;
    padding: 20px;
    font-weight: 600;
    border: 1px solid #ff914d;
    text-align: center;
}

#apartments .popup>div p:hover {
    cursor: pointer;
    color: white;
    background-color: #ff914d;
}

#apartments .switch {
    position: absolute;
    right: 10px;
    display: flex;
    top: 135px;
    gap: 10px;
}

#apartments .switch .icon{
    font-size: 25px;
    cursor: pointer;
    color: black;
}

#apartments .switch p{
    color: black;
}

@media (max-width: 500px) {
    #apartments .popup>div {
        padding: 20px;
        margin: 0 20px;
    }
    #apartments .popup>div p {
        width: 180px;
    }
    #apartments .popup>div h1 {
        text-align: center;
    }

}

/*APARTMENTS */

#apartments>h1 {
    text-align: center;
    font-size: 36px;
    padding: 80px 0 50px;
}

@media (max-width: 1000px) {
    #apartments .switch p {
        display: none;
    }
    #apartments .switch {
        right: 20px;
    }
    #apartments>h1 {
        padding-right: 30px;
    }
}

#apartments .container {
    display: flex;
    column-gap: 40px;
    row-gap: 60px;
    max-width: 1320px;
    flex-wrap: wrap;
    margin: auto;
}

@media (max-width: 1460px) {
    #apartments .container {
        max-width: 840px;
    }
}

@media (max-width: 980px) {
    #apartments .container {
        max-width: 400px;
    }
}


#apartments .container .apartment {
    width: 400px;
    position: relative;
    cursor: pointer;
}

#apartments .container .available {
    position: absolute;
    background-color: #FFE4d4;
    border-radius: 15px;
    padding: 2px 8px;
    width: max-content;
    top: 20px;
    left: 10px;
    color: black;
    z-index: 2;
}

#apartments .container .photo {
    width: inherit;
    height: 250px;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.5);
    position: relative;
}

#apartments .container .photo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
}

#apartments .container .full .photo img {
    filter: grayscale(1);
}

#apartments .container .full .full-text {
    position: absolute;
    z-index: 2;
    font-size: 60px;
    color: #ff914d;
    text-transform: uppercase;
    font-weight: 600;
    top: calc(50% - 40px);
    left: 45px;
    transform: rotate(-28deg);
    text-shadow: 0 10px 15px rgba(0, 0, 0, 0.5);
}

html[lang="fr"] #apartments .container .full .full-text {
    left: 60px;
}

#apartments .container h2 {
    font-weight: 600;
    margin-top: 20px;
}

#apartments .container .keywords {
    color: black;
    position: relative;
    font-style: italic;
    height: 80px;
}

@media (max-width: 900px) {
    #apartments .container .keywords {
        height: fit-content;
        margin-bottom: 20px;
    }
}

#apartments .container .price1 {
    font-size: 14px;
    color: #222222;
    position: relative;
    top: 4px;
}

#apartments .container .price2 {
    font-size: 18px;
    color: black;
}

#apartments .container .price2 span {
    font-weight: 700;
}

/* ONLY */

#apartments .container .only .photo>div {
    position: absolute;
    height: 30px;
    width: 300px;
    background-color: #ff914d;
    bottom: 45px;
    right: -40px;
    transform: rotate(-35deg);
}

#apartments .container .only .photo>div p {
    position: relative;
    left: 80px;
    color: white;
}

#apartments .container .only .price2 {
    color: #666666;
    text-decoration: line-through;
}

#apartments .container .only .price3 {
    font-size: 18px;
    color: black;
}

#apartments .container .only .price3 span {
    font-weight: 700;
}

#apartments .container .only .promotion {
    position: absolute;
    bottom: 20px;
    right: 0px;
    color: white;
    background-color: red;
    padding: 2px 4px;
    border-radius: 10px;
    font-size: 15px;
}

@media (max-width: 500px) {
    #apartments .container .apartment {
        width: 300px;
    }
    #apartments .container .photo {
        height: 190px;
    }
    #apartments .container {
        max-width: 300px;
    }
    #apartments .container .full .full-text {
        font-size: 50px;
        top: calc(50% - 37px);
        left: 22px;
    }
    html[lang="fr"] #apartments .container .full .full-text {
        left: 36px;
        top: calc(50% - 38px);
    }
}