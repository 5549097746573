@keyframes animNav {
    0% { top: -70px; }
    100% { top: 0; }
  }

header {
    width: 100vw;
    height: 70px;
    z-index: 990;
}

.fixed {
    position: absolute;
}

.sticky {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.667);
    animation: animNav 0.5s;
}

#logo {
    margin-left: 30px;
    position: relative;
    bottom: 10px;
    justify-self: center;
    width: 110px;
}

nav {
    position: relative;
    top: 10px;
    display: flex;
    max-width: 1200px;
    height: 70px;
    margin: auto;
    align-items: end;
}

nav .menu {
    margin-left: auto;
    position: relative;
    right: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding-bottom: 12px;
    z-index: 900;
}

nav .menu .link {
    text-decoration: none;
    color: #e4e4e4;
    font-size: 16px;
    font-weight: 600;
}

nav .mobile-btn {
    display: none;
    flex-direction: column;
    gap: 4px;
    cursor: pointer;
    position: absolute;
    top: 25px;
    right: 40px;
    z-index: 900;
    
}

nav .mobile-btn .bar1, nav .mobile-btn .bar2, nav .mobile-btn .middle-bar {
    width: 22px;
    height: 2px;
    background-color: black;
    position: relative;
    transition: all 0.4s;
    opacity: 1;
    background-color: #ff914d;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

nav .mobile-btn .rotated1 {
    transform: rotate(45deg);
    top: 6px;
    background-color: white;
}

nav .mobile-btn .rotated2 {
    transform: rotate(-45deg);
    bottom: 6px;
    background-color: white;
}

nav .mobile-btn .vanish {
    opacity: 0;
}

nav .menu .link:hover {
    color: #ff914d;
}

nav .langage-selector {
    display: flex;
    flex-direction: column;
    padding-right: 50px;
}

nav .langage-selector .flag {
    width: 30px;
    height: 21px;
    padding-bottom: 30px;
    cursor: pointer;
    position: relative;
    top: 15px;
}

nav .langage-selector .selector {
    background-color: white;
    border-radius: 4px;
    position: absolute;
    top: 80px;
    right: -10px;
    width: 150px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
}

nav .langage-selector .selector-active {
    opacity: 1;
    visibility: visible;
    top: 55px;
}

nav .langage-selector .selector ul li {
    margin: 10px;
    color: #666666;
    font-size: 0.9rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.4s;
}

nav .langage-selector .selector ul li:hover {
    position: relative;
    margin-left: 15px;
    color: #ff914d;
}

@media screen and (max-width: 900px) {
    nav  {
        overflow-y: visible;
    }
    nav .menu {
        flex-direction: column;
        align-items: baseline;
        position: absolute;
        top: 50px;
        left: 40px;
        opacity: 0;
        visibility: hidden;
    }
    header .menu-active {
        opacity: 1;
        visibility: visible;
        transition: all 0.3s;
    }
    header .mobile-btn {
        display: flex;
    }
    nav .menu .link {
        color: white;
        text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
        font-size: 20px;
    }
    header .mobile-menu {
        position: absolute;
        width: 100vw;
        height: 300px;
        background: linear-gradient(220deg, #f0b985 19%, #ff914d 92%);
        top: 0px;
        z-index: 800;
        transition: all 0.3s;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
    }
    
    header .mobile-menu-close {
        height: 0px;
    }
    nav .langage-selector .selector {
        left: 50px;
    }
}