#rooms .carousel-container {
  position: relative;
  width: 100%;
  max-width: 600px;
  overflow: hidden;
  margin: 0 auto;
}

#rooms .carousel {
  display: flex;
  overflow: hidden;
}

#rooms .carousel-slide {
  min-width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 444px) {
  #rooms .carousel-slide {
    height: 225px;
  }
}

#rooms .carousel-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  display: block;
}


#rooms .carousel-indicators {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 5px;
}

#rooms .indicator {
  width: 10px;
  height: 10px;
  background-color: #fff;
  border: none;
  border-radius: 50%;
  opacity: 0.5;
  cursor: pointer;
  padding: 0;
}

#rooms .indicator.active {
  opacity: 1;
}

#rooms .carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
}

#rooms .carousel-button.prev {
  left: 10px;
}

#rooms .carousel-button.next {
  right: 10px;
}

#rooms .carousel-container.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  max-width: none;
  background: rgba(0, 0, 0, 0.9);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

#rooms .carousel-container.fullscreen .carousel {
  width: 100%;
  height: 100%;
}

#rooms .carousel-container.fullscreen .carousel-slide img,
#rooms .carousel-container.fullscreen .carousel-slide video {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

#rooms .carousel-container.fullscreen .carousel-slide {
  width: 100%;
  height: 100%;
}

#rooms .close-fullscreen {
  position: fixed;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  z-index: 1001;
}