#home .header-trigger {
    position: absolute;
    top: 0;
    width: 100vw;
    height: 70px;
}

#home .btn {
    color: white;
    text-decoration: none;
    font-size: 20px;
    width: 200px;
    background-color: #ff914d;
    padding: 12px 30px;
    border-radius: 5px;
    font-weight: 600;
    text-align: center;
    transition: all 0.3s;
    position: relative;
}

#home .btn:hover {
    color: #270799;
    cursor: pointer;
    background-color: #F59A0E;
}

/* LANDIND PAGE */

#home .landing .bg {
    position: absolute;
    height: 100vh;
    width: 100vw;
    background-size: cover;
    background-position: center center;
    filter: brightness(75%);
    z-index: -1;
}

#home .landing {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

#home .landing .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    column-gap: 200px;
    row-gap: 50px;
}

#home .landing .container .text {
    font-weight: 500;
    color: white;
    font-size: 60px;
    text-align: end;
    line-height: 1.2;
    width: 565px;
}

#home .landing .container .text span {
    font-weight: 800;
    color: #F59A0E;
}

@media screen and (max-width: 700px) {
    #home .landing .container .text {
        font-weight: 500;
        color: white;
        font-size: 40px;
        text-align: center;
        line-height: 1.2;
        
    }
    #home .landing .container {
        width: 80vw;
    }
}


#home .landing .container .btn {
    margin: 0 10px;
}

/*BENEFITS*/

#home .benefits {
    margin: 80px 0;
    display: flex;
    justify-content: center;
    column-gap: 100px;
    row-gap: 50px;
    flex-wrap: wrap;
}

#home .benefits .with {
    width: 400px;
}

#home .benefits .with h1 {
    border-radius: 50px;
    padding: 20px 0;
    background-color: #ff914d;
    text-align: center;
    font-size: 32px;
    margin-bottom: 20px;
}

#home .benefits .with img {
    width: 28px;
    object-fit: contain;
}

#home .benefits .with li p {
    font-size: 1.2rem;
}

#home .benefits .with li:last-child {
    border-bottom: none;
}

#home .benefits .with ul {
    background-color: #FFE4D4;
    border-radius: 20px;
}

#home .benefits .without {
    width: 350px;
    margin-top: 20px;
}

#home .benefits .without h1 {
    border-radius: 50px;
    padding: 18px 0;
    background-color: grey;
    text-align: center;
    margin-bottom: 20px;
}

#home .benefits .without img {
    width: 22px;
    object-fit: contain;
}

#home .benefits li {
    display: flex;
    gap: 10px;
    padding: 10px;
    border-bottom: 1px solid #ddd;
}

#home .benefits li p {
    color: black;
}

@media screen and (max-width: 800px) {
    #home .benefits {
        margin: 80px 20px;
    }
}

/* FIND */

#home .find {
    background-color: #E9E9E9;
    padding: 80px 20px;
}

#home .find h1 {
    text-align: center;
}

#home .find .container {
    margin-top: 50px;
    display: flex;
    column-gap: 100px;
    row-gap: 50px;
    justify-content: center;
    flex-wrap: wrap;
}

#home .find .container>img {
    width: 300px;
    height: 400px;
    padding: 10px;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

#home .find .container .steps-container {
    display: flex;
    flex-direction: column;
    gap: 70px;
}

#home .find .container .steps-container .steps {
    display: flex;
    gap: 35px;
    max-width: 600px;
    min-height: 110px;
    position: relative;
}

#home .find .container .steps-container .steps .arrow1 {
    position: absolute;
    top: 94px;
    left: -40px;
    width: 100px;
    transform: rotateY(180deg);
}

#home .find .container .steps-container .steps .arrow2 {
    position: absolute;
    top: 94px;
    left: 20px;
    width: 100px;
}

@media screen and (max-width: 700px) {
    #home .find .container .steps-container .steps .arrow1 {
        display: none;
    }
    #home .find .container .steps-container .steps .arrow2 {
        display: none;
    }
}

#home .find .container .steps-container .steps .step-img {
    width: 75px;
    object-fit: contain;
}

#home .find .container .steps-container .btn {
    bottom: 20px;
}

@media screen and (max-width: 1059px) {
    #home .find .container .steps-container .btn {
        align-self: center;
    }
}

/* APARTMENTS */

#home .apartments {
    background-position: center;
    background-size: cover;
    padding-bottom: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#home .apartments h1 {
    text-align: center;
    padding: 80px 0 40px 0;
}

#home .apartments .slider {
    overflow: hidden;
    width: 1140px;
}

#home .apartments .slider-container {
    display: flex;
    gap: 30px;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    overflow-x: auto;
    overflow-y: hidden;

    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

#home .apartments .slider-container::-webkit-scrollbar {
    display: none;
    /* Chrome, Safari and Opera */
}

#home .apartments .slider-container .image {
    margin-bottom: 20px;
    scroll-snap-align: start;
    height: 300px;
    width: 360px;
}

#home .apartments .slider-container a {
    overflow: hidden;
    display: block;
    border-radius: 5px;
    height: inherit;
    width: inherit;
}

#home .apartments .slider-container a img {
    transition: all 0.5s;
    width: inherit;
    height: inherit;
    object-fit: cover;
    display: block;
}

#home .apartments .slider-container a img:hover {
    transform: scale(1.15) rotate(2deg);
}

#home .slider-control {
    position: relative;
    overflow: visible;
    padding: 25px;
}

#home .apartments .slider-control .left-btn,
#home .apartments .slider-control .right-btn {
    position: absolute;
    color: #F59A0E;
    background-color: white;
    padding: 15px;
    border-radius: 50%;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
    transition: all 0.3s;
    z-index: 2;
    top: calc(50% - 25px);
    font-size: 20px;
    opacity: 0;
}

#home .apartments .slider-control .left-btn {
    left: 60px;
}

#home .apartments .slider-control .right-btn {
    right: 60px;
}

#home .apartments .slider-control:hover .left-btn {
    transform: translateX(-140%);
    opacity: 1;
}

#home .apartments .slider-control:hover .right-btn {
    transform: translateX(140%);
    opacity: 1;
}

#home .apartments .slider-control .right-btn:hover,
#home .apartments .slider-control .left-btn:hover {
    background-color: #F59A0E;
    color: white;
    cursor: pointer;
}

#home .apartments .slider-control .dots {
    position: absolute;
    bottom: -35px;
    left: calc(50% - 32.5px);
    /* remove 12.5px * num of slides - 5px  */
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

#home .apartments .slider-control .dots div {
    width: 15px;
    height: 15px;
    background-color: #d8d8d8;
    border-radius: 50%;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
    cursor: pointer;
}

#home .apartments .slider-control .dots .active {
    background-color: #F59A0E;
}

#home .apartments .btn {
    margin: 100px 0 50px;
}

@media screen and (max-width: 1240px) {
    #home .apartments .slider {
        width: 900px;
    }
    #home .apartments .slider-container .image {
        width: 435px;
        height: 345px;
    }
    #home .apartments .slider-control:hover .left-btn {
        transform: none;

    }
    #home .apartments .slider-control:hover .right-btn {
        transform: none;
    }
    #home .apartments .slider-control .left-btn {
        left: 0px;
        opacity: 1;
    }
    
    #home .apartments .slider-control .right-btn {
        right: 0px;
        opacity: 1;
    }
    #home .apartments .slider-control .dots {
        left: calc(50% - 60px);
    }
}

@media screen and (max-width: 980px) {
    #home .apartments .slider {
        width: 600px;
    }

    #home .apartments .slider-container .image {
        width: 600px;
    }
    #home .apartments .slider-control .dots {
        left: calc(50% - 105px);
    }
}

@media screen and (max-width: 700px) {
    #home .apartments .slider {
        width: 400px;
    }

    #home .apartments .slider-container .image {
        width: 400px;
    }
    #home .apartments {
        padding: 0 20px;
    }
}

@media screen and (max-width: 465px) {
    #home .apartments .slider {
        width: 300px;
    }

    #home .apartments .slider-container .image {
        width: 300px;
        height: 280px;
    }
}

@media screen and (max-width: 370px) {
    #home .apartments .slider {
        width: 250px;
    }

    #home .apartments .slider-container .image {
        width: 250px;
        height: 225px;
    }
}

/* CITIES */

#home .cities {
    background-color: #E9E9E9;
    padding: 80px 20px;
}

#home .cities > div {
    display: flex;
    flex-wrap: wrap;
    max-width: 1260px;
    margin: auto;
    justify-content: center;
    align-items: center;
    gap: 40px;
}

#home .cities .photo {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    width: 600px;
    border: solid 10px white;
} 

#home .cities .photo img {
    display: block;
    max-width: 100%;
}

#home .cities .container {
    width: 600px;
    display: flex;
    flex-direction: column;
}

#home .cities .container h2 {
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 20px;
}

#home .cities .container p {
    line-height: 28px;
    padding-right: 50px;
}

#home .cities .btn {
    margin-top: 20px;
    width: 300px;
}

/* INCLUDE */

#home .include {
    background-color: #f8f8f8;
    padding: 20px 0px 100px;
}

#home .include h1 {
    text-align: center;
    padding: 60px 0;
}

#home .include>div {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0 40px;
    max-width: 1200px;
    margin: auto;
    column-gap: 20px;
    row-gap: 30px;
}

#home .include .container {
    display: flex;
    gap: 15px;
    max-width: 380px;
    min-width: 100px;
}

#home .include .container .icon {
    min-width: 50px;
    width: 50px;
    margin-top: 10px;
}

#home .include .container .icon img {
    width: 100%;
}

#home .include .container h2 {
    margin-bottom: 10px;
}

/* CREATORS */

#home .creators {
    padding: 20px 0px 100px;
}

#home .creators h1 {
    text-align: center;
    padding: 60px 0;
}

#home .creators>div {
    display: flex;
    flex-wrap: wrap;
    max-width: 1220px;
    margin: auto;
    justify-content: center;
    gap: 20px;
}

#home .creators .photo {
    width: 600px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

#home .creators .photo img {
    display: block;
    max-width: 100%;
}

#home .creators .container {
    width: 600px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 10px;
}

#home .creators .container:hover,
#home .creators .photo:hover {
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
}

#home .creators .container h3 {
    color: #F59A0E;
    font-size: 18px;
    font-weight: 600;
    margin: 15px 0 5px;
}

#home .creators .container h4 {
    color: #666666;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
}

#home .creators .container p {
    padding: 0 30px;
    line-height: 26px;
    font-size: 15px;
}

#home .creators .container img {
    width: 280px;
}

@media screen and (max-width: 1200px) {
    #home .creators .container {
        margin: 0 20px;
    }

    #home .creators .photo {
        margin: 0 20px;
    }
    #home .creators .container img {
        width: 230px;
    }
}