* {
    -webkit-user-select: none;
    -moz-user-select: none; /* Pour Firefox */
    -ms-user-select: none;  /* Pour IE/Edge */
    user-select: none;      /* Standard */
  }

p,
a,
li,
h1,
h2,
h3,
h4 {
    margin: 0;
    font-family: 'Poppins';
}

body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

h1, h2, h3, h4 {
    color: #222222;
}

h1 {
    font-size: 28px;
    font-weight: 600;
}

h2 {
    font-size: 20px;
    font-weight: 500;
}

p {
    font-size: 16px;
    color: #666666;
}

.text-color {
    color: #ff914d;
}